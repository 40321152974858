import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { persistReducer, persistStore, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import initialize from './initializeStore';
import rootReducer from './rootReducer';
import { State } from './interfaces';

const persistConfig: PersistConfig<State> = {
  key: 'root',
  storage,
  // whitelist: ['authUser'],
  whitelist: [
    'authState',
    'commonState',
    'appSettingsState',
    'productDetailsState',
    'cartState',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// preloadedState (optional): represents initial redux state (rendered on server)
const configureStore = () => {
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk, loadingBarMiddleware()))
  );

  const persistor = persistStore(store, null, () => {
    initialize(store);
  });
  return { store, persistor };
};

export default configureStore;
