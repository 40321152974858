/// <reference types="webpack-env" />

// import { createLogger } from 'redux-logger';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import { persistReducer, persistStore, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import initialize from './initializeStore';
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { State } from './interfaces';

const persistConfig: PersistConfig<State> = {
  key: 'root',
  storage,
  // whitelist: ['authUser'],
  whitelist: [
    'authState',
    'commonState',
    'appSettingsState',
    'productDetailsState',
    'cartState',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const store = createStore(
    persistedReducer,

    composeWithDevTools(applyMiddleware(thunk, loadingBarMiddleware()))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(rootReducer as any);
    });
  }

  const persistor = persistStore(store, null, () => {
    initialize(store);
  });
  return { store, persistor };
};

export default configureStore;
